import { navigate } from 'gatsby'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonBase,
} from '@mui/material'

import { useFetchUsers } from '@/hooks/api'
import Loader from '@/components/loader'
import ErrorMessage from '@/components/errorMessage'

const UsersTable = () => {
  const { loading, data, error } = useFetchUsers()
  const patients = data?.items || []

  if (loading) {
    return <Loader size={`sm`} />
  }

  if (error) {
    return (
      <ErrorMessage
        message={(error as string) || `There was an error while fetching users`}
      />
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>Cliënten</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {patients.map((patient) => (
            <TableRow
              key={patient.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <ButtonBase
                  onClick={() =>
                    navigate(`/insight/${patient._id}`, {
                      state: { userName: patient.name },
                    })
                  }
                >
                  {patient.name}
                </ButtonBase>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UsersTable
