import { useEffect } from 'react'
import type { PageProps } from 'gatsby'

import Layout from '@/components/layout'
import { useAuth0 } from '@/services/auth'
import UsersTable from '@/components/usersTable'
import Loader from '@/components/loader'

const HomePage = (props: PageProps) => {
  const { authLoading, isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (authLoading || isAuthenticated) {
      return
    }

    const loginAsync = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      })
    }
    loginAsync()
  }, [authLoading, isAuthenticated, loginWithRedirect])

  if (authLoading || !isAuthenticated) {
    return <Loader />
  }

  return (
    <Layout {...props}>
      <>
        <UsersTable />
      </>
    </Layout>
  )
}

export default HomePage
